import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'
import { useMemo, createElement } from 'react'
import { useLayoutMode } from 'src/userSettingNavigation/useLayoutMode'
import { CheckCircleFillIcon } from '@micin-jp/icons'

// Chakra の toast にデフォルトのオプションだけ付与して返す
export const useToast = () => {
  const layoutMode = useLayoutMode()
  const defaultOptions: UseToastOptions = useMemo(
    () => ({
      icon: createElement(CheckCircleFillIcon),
      position: 'top',
      variant: 'solid',
      containerStyle: {
        width: '100%',
        maxWidth: layoutMode === 'tablet' ? 'container.lg' : 'container.fluid',
        marginTop: '72px',
        paddingX: layoutMode === 'tablet' ? 4 : 48,
      },
    }),
    [layoutMode],
  )
  return useChakraToast(defaultOptions)
}
